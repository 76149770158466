<h4><i class="fas fa-paperclip"></i> Attachments</h4>

<div class="" style="min-height: 50px;">
  <div class="column is-one-third">
    <div class="field">
      <div class="file has-name is-primary is-fullwidth">
        <label class="file-label" [hidden]="fileToupload">
          <input
            class="file-input"
            style="display: none;"
            type="file"
            name="file"
            #fileInput
            (change)="handleFileInput($event.target.files)"
          />
          <span
            class="btn waves-effect waves-light btn-rounded btn-sm attach-btn"
          >
            <span class="file-icon">
              <i class="fas fa-upload"></i>
            </span>
            <span class="file-label">
              Select a file to upload…
            </span>
          </span>
        </label>

        <div *ngIf="fileToupload">
          <strong>{{ fileToupload.name }}</strong>
          <button
            class="btn waves-effect waves-light btn-rounded btn-sm ml-2 attach-btn"
            (click)="handleAttach()"
          >
            Attach
          </button>
          <button
            class="btn waves-effect waves-light btn-rounded btn-secondary btn-sm ml-1"
            (click)="handleUploadCancel()"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" style="box-sizing: content-box!important;">
  <ng-container *ngFor="let att of activeAttachements">
    <div class="col-xlg-2 col-lg-4 col-md-4 col-sm-6 col-xs-12">
      <div class="card rounded" style="background-color: #f8f9fa;">
        <div class="card-body p-2">
          <div class="d-flex align-items-center">
            <div class="m-r-10">
              <span class="text-cyan" style="font-size: 30px;">
                <i
                  class="{{ getFileTypeIcon(att.attachment.attachmentType) }}"
                ></i>
              </span>
            </div>
            <div>
              <a href="javascript: void(0);" (click)="handleDownloadFile(att)" title="{{att.attachment.userFileName}}" >{{ att.attachment.userFileName | textLimit:15 }}</a>
            </div>
            <div class="ml-auto">
              <button
                class="btn btn-xs text-danger"
                style="font-size: larger;"
                (confirm)="handleRemoveAttachement(att)"
                [swal]="deleteSwal"
              >
                <i class="ti-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Card -->
  <!-- Column -->
</div>

<swal
  #deleteSwal
  title="Delete attachment"
  text="Do you want to delete this attachment?"
  icon="question"
  [showCancelButton]="true"
  confirmButtonColor="#d33"
  confirmButtonText="Yes, Delete!"
>
</swal>
