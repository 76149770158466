import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CustomField } from 'src/app/app-services';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-custom-form',
  templateUrl: './custom-form.component.html',
  styleUrls: ['./custom-form.component.scss'],
})
export class CustomFormComponent implements OnInit, OnChanges {
  @Input() formFields: Array<CustomField>;
  @Input() detailsForm: FormGroup;
  @Input() formData: any;

  constructor(private formBuilder: FormBuilder) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.initData();
  }

  ngOnInit(): void {
    this.detailsForm = new FormGroup({});
  }

  initData() {
    const formGroup = {};
    this.formFields.forEach((formControl) => {
      if (formControl.isRequired)
        this.detailsForm.addControl(
          formControl.name,
          this.formBuilder.control('', Validators.required)
        );
      else
        this.detailsForm.addControl(
          formControl.name,
          this.formBuilder.control('')
        );
    });
  }
  onSubmit(formValues: any) {
  }
}
