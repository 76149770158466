import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { AuthorizeGuard } from 'api-authorization/authorize.guard';

const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthorizeGuard],
    children: [
      // { path: '', redirectTo: '/projects', pathMatch: 'full' },
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboards/dashboards.module').then(
            (m) => m.DashboardsModule
          ),
      },
      {
        path: 'publication',
        loadChildren: () =>
          import('./publication/publication.module').then((m) => m.PublicationModule),
      },
      {
        path: 'pooled-estimates',
        loadChildren: () =>
          import('./pooledestimates/pooledestimate.module').then((m) => m.PooledEstimateModule),
      },
      {
        path: 'assets',
        loadChildren: () =>
          import('./assets/assets.module').then((m) => m.AssetsModule),
      },
      {
        path: 'projects',
        loadChildren: () =>
          import('./tickets/tickets.module').then((m) => m.TicketsModule),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'catalog',
        loadChildren: () =>
          import('./product-catalog/product-catalog.module').then(
            (m) => m.ProductCatalogModule
          ),
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('./admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('./appreports/appreports.module').then(
            (m) => m.AppreportsModule
          ),
      },
    ],
  },
  // { path: '**', redirectTo: 'tickets', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
