import { Injectable } from '@angular/core';
import {
  NgbDateAdapter,
  NgbDateStruct,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
  readonly DELIMITER = '/';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value != null) {
      if(moment(value,'MM-DD-YYYY',true).isValid() || moment(value,'M-D-YYYY',true).isValid() || moment(value,'M-DD-YYYY',true).isValid() || moment(value,'MM-D-YYYY',true).isValid())
      {
        value = value.replace(/-/gi,'/');
      }
      const date = (moment(value,'MM-DD-YYYY',true).isValid() || moment(value,'M-D-YYYY',true).isValid() || moment(value,'M-DD-YYYY',true).isValid() || moment(value,'MM-D-YYYY',true).isValid()  
        || moment(value,'MM/DD/YYYY',true).isValid() || moment(value,'M/D/YYYY',true).isValid() || moment(value,'M/DD/YYYY',true).isValid() || moment(value,'MM/D/YYYY',true).isValid())
        && value != null && parseInt(String(value).slice(-4)) > 0
        ? moment(value).format('MM/DD/YYYY').toString().split(this.DELIMITER)
        : null;
      // const date = value.split(this.DELIMITER);
      if(!date) return null
      return {
        day: parseInt(date[1], 10),
        month: parseInt(date[0], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date
      ? date.month + this.DELIMITER + date.day + this.DELIMITER + date.year
      : null;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[1], 10),
        month: parseInt(date[0], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? date.month.toString().padStart(2, '0') +
          this.DELIMITER +
          date.day.toString().padStart(2, '0') +
          this.DELIMITER +
          date.year
      : '';
  }
}