<form [formGroup]="detailsForm">
  <ng-template ngFor let-input [ngForOf]="formFields">
    <div class="col-sm-12 col-md-4">
      <ng-container [ngSwitch]="input.type">
        <!-- here we will be dynamically creating our form fields-->
        <ng-template [ngSwitchCase]="'TextField'">
          <div class="form-group">
            <label [for]="input.name"> {{ input.displayText }}</label>
            <input
              [formControlName]="input.name"
              type="text"
              [name]="input.name"
              [placeholder]="'Enter ' + input.displayText"
              class="form-control"
              [required]="input.isRequired"
            />

            <div
              class="error"
              *ngIf="
                detailsForm.get(input.name) &&
                detailsForm.get(input.name).invalid &&
                (detailsForm.get(input.name).dirty ||
                  detailsForm.get(input.name).touched)
              "
            >
              <div
                *ngIf="
                  detailsForm.get(input.name) &&
                  detailsForm.get(input.name).errors.required
                "
              >
                Input required
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </div>
  </ng-template>
</form>
