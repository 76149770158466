import { Injectable, OnDestroy } from "@angular/core";
import { CreateIdentityUserCommand, UpdateIdentityUserCommand, UserDetailsDto, UsersClient } from "../app-services";
import { SubSink } from 'SubSink';
import { AuthorizeService } from "../../../api-authorization/authorize.service";
import { IdentityServerService } from "./identity-server.service";
import { take } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UserSyncService implements OnDestroy {
  private sub = new SubSink();
  userId: number;
  userDetails: UserDetailsDto;
  userData;

  constructor(private usersClient: UsersClient,
    private identityServerService: IdentityServerService,
    //private auth: AuthorizeService
  ) {

  }

  syncUserData(loggedInUserData) {
    let ptUserDetails: UserDetailsDto;
    this.userData = loggedInUserData;
    if (this.userData !== null && this.userData !== undefined && this.userData.UserId > 0) {
      let userEmailAddress: string = this.getEmailAddress(this.userData);

      this.sub.sink = this.usersClient.getDetailsByEmailAddress(userEmailAddress).pipe(take(1)).subscribe((data: any) => {

        ptUserDetails = data;
        let identityServerUserDetails: UserDetailsDto;

        this.sub.sink = this.identityServerService.getDetailsByEmailAddress(userEmailAddress).pipe(take(1)).subscribe((data: any) => {
          identityServerUserDetails = data;

          if (ptUserDetails === null || ptUserDetails === undefined) {
            this.createUser(identityServerUserDetails);
          } else {
            this.updateUser(identityServerUserDetails);
          }
        });
      });
    }

  }

  getEmailAddress(userData) {
    return userData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"];
  }

  createUser(userData) {
    let user = {
      firstname: userData.firstname,
      lastname: userData.lastname,
      title: userData.title,
      email: userData.email,
      address: userData.address,
      departments: null,//[parseInt(userData.departments)],
      isActive: true,
      reportingManagerId: userData.reportingManagerId,
      notes: '',
      locationId: parseInt(userData.locationId),
      role: userData.role,
      phoneNumber: userData.phoneNumber,
      mobileNumber: userData.mobileNumber,
      company: userData.company,
      isWorkFromHome: userData.isWorkFromHome,
      userId: this.userData.UserId,
      subjectId: this.userData.sub
    };

    this.sub.sink = this.usersClient
      .createIdentityUser(new CreateIdentityUserCommand(user))
      .pipe(take(1))
      .subscribe((data) => { },
        (error) => {
          if (error.response) {
            let ex = JSON.parse(error.response);
            if (ex.errors) {
              //this.processErrors(ex.errors);
              //console.log(ex.errors);
            } else {
              //this.notifications.error('', error);
              //console.log(error);
            }
          }
        }
      );
  }

  updateUser(userData) {
    let user = {
      firstname: userData.firstname,
      lastname: userData.lastname,
      title: userData.title,
      email: userData.email,
      address: userData.address,
      //departments: null,//[parseInt(userData.departments)],
      isActive: true,
      reportingManagerId: userData.reportingManagerId,
      notes: '',
      locationId: parseInt(userData.locationId),
      role: userData.role,
      phoneNumber: userData.phoneNumber,
      mobileNumber: userData.mobileNumber,
      company: userData.company,
      isWorkFromHome: userData.isWorkFromHome,
      userId: this.userData.UserId,
      subjectId: this.userData.sub
    };

    this.sub.sink = this.usersClient
      .updateIdentityUser(new UpdateIdentityUserCommand(user))
      .pipe(take(1))
      .subscribe((data) => { },
        (error) => {
          if (error.response) {
            let ex = JSON.parse(error.response);
            if (ex.errors) {
              //this.processErrors(ex.errors);
              //console.log(ex.errors);
            } else {
              //this.notifications.error('', error);
              //console.log(error);
            }
          }
        }
      );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
