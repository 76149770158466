import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { NotificationsService } from 'angular2-notifications';
import { AuthorizeService } from 'api-authorization/authorize.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { first, take } from 'rxjs/operators';
import { AddDeliverableCommand, AddDeliverablePublicationCommand, Attachement, AttachmentsClient, DeleteDeliverableCommand, Deliverable, DeliverableAttachment, DeliverableClient, DeliverableDto, DeliverablePublication, EHAddDeliverableCommand, EHDeliverableClient, EHDeliverableInputDto, RemoveDeliverablePublicationCommand, Ticket, TicketAttachement, TicketsClient, UpdateDeliverableCommand, UpdateDeliverablePublicationCommand } from 'src/app/app-services';
import { SubSink } from 'SubSink';
import * as moment from 'moment';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-deliverable-modal',
  templateUrl: './add-deliverable-modal.component.html',
  styleUrls: ['./add-deliverable-modal.component.scss']
})
export class AddDeliverableModalComponent implements OnInit {

  private sub = new SubSink();
  isReadOnly = true;
  isEmployee = false;
  isManager = false;
  isAdmin = false;
  isAgent = false;

  @Output()
  handleContentChange:EventEmitter<boolean> = new EventEmitter<boolean>();

  private ticketDetails: Ticket;
  isEditMode = false;
  deliverables: Array<Deliverable> = [];
  deliverableForm:FormGroup;
  anticipatedStartDate;
  userData;
  deliverableId = 0;
  keyDeliverables;
  deliverableLink;
  expectedDueDate;
  completionDate;
  valueMessage;
  keyInsights;
  product;
  indication;
  keyDeliverableValues: Array<string> = [];
  attachments:Array<DeliverableAttachment> = []
  public scrollConfig: PerfectScrollbarConfigInterface = {};
  projectRole;
  projectAnticipatedStartDate;
  projectAnticipatedEndDate;
  formattedDate: string = '';
  separator = '/';
  valueMessagesList:Array<string> = [];
  productList: Array<string> = [];
  indicationList: Array<string> = [];
  showTooltip: boolean = false;
  deliverableDto: EHDeliverableInputDto;

  constructor(
    private route: ActivatedRoute,
    private ticketsClient: TicketsClient,
    private notifications: NotificationsService,
    private auth: AuthorizeService,
    private formBuilder: FormBuilder,
    private loadingBar: LoadingBarService,
    private attchmentClinet: AttachmentsClient,
    private deliverableClient: EHDeliverableClient,
    private spinner: NgxSpinnerService,
    private router: Router,
    public activeModal: NgbActiveModal) { 
      this.deliverableForm = this.formBuilder.group({
        keyDeliverables: ['', Validators.required],
        deliverableLink: ['', null],
        completionDate: ['', null],
        valueMessage: [''],
        keyInsights: [''],
        product:['', Validators.required],
        indication: [''],
      });
    }

  async ngOnInit(): Promise<void> {
    console.log("success");
    this.auth.getUser().subscribe(data => {
      this.userData = data;
    })
    this.isEmployee = await this.auth.isEmployee().pipe(take(1)).toPromise();
    this.isManager = await this.auth.isManager().pipe(take(1)).toPromise();
    this.isAdmin = await this.auth.isAdmin().pipe(take(1)).toPromise();
    this.isAgent = await this.auth.isAgent().pipe(take(1)).toPromise();
    this.isReadOnly = this.isEmployee;
    // this.sub.sink = this.route.paramMap.subscribe((params) => {
    //   // this.ticketNumber = params.get('number');
    //   // this.ticketId = Number(this.ticketNumber.substring(2));
    //   this.getDeliverableListByTicketId();
    //   this.getTicketDetails();
    // });
    
    this.getCustomFieldsValues();
    //this.getTicketDetails();
  }

  getCustomFieldsValues()
  {
    this.spinner.show();
    this.sub.sink = this.deliverableClient.getEHDeliverableInput().subscribe(
      (data) => {
        this.deliverableDto = data;
        this.keyDeliverableValues = this.deliverableDto.keyDeliverables;
        this.valueMessagesList = this.deliverableDto.valueMessages;
        this.productList = this.deliverableDto.products;
        this.indicationList = this.deliverableDto.indications;
        this.spinner.hide();
      },
      (error) => {
        this.notifications.error(
          'Error',
          'Something went wrong, try again later.'
        );
        this.spinner.hide();
      }
    );
  }

  onDateInput(event: any) {
    const textSoFar = event.target.value;
    if (textSoFar.length === 2 || textSoFar.length === 5) {
      if (textSoFar[textSoFar.length - 1] !== this.separator) {
        const formattedText = textSoFar + this.separator;
        this.formattedDate = formattedText;
        event.target.value = this.formattedDate;
      }
    } else if (textSoFar.length > 10) {
      const formattedText = textSoFar.slice(0, 10);
      this.formattedDate = formattedText;
      event.target.value = this.formattedDate;
    } else {
      this.formattedDate = textSoFar;
      event.target.value = this.formattedDate;
    }
  }

  handleAttachementChnage(atts:Array<Attachement>){
    this.attachments = [...atts];
    console.log("handleAttachementChnage:",this.attachments);
  }

  onSubmitDeliverableForm() {
    if (this.deliverableForm.valid) {
      this.addDeliverable();
    }
  }
  
  


  addDeliverable()
  {
    if (this.attachments.length === 0 && !this.deliverableLink) {
      this.notifications.error('Error', 'Please provide at least one attachment or a deliverable link.');
      return; // Exit the function without proceeding further
    }
    console.log("Value Messages: ", this.valueMessage);
    const deliverableDetails = new EHAddDeliverableCommand({
      keyDeliverables: this.deliverableForm.get('keyDeliverables').value,
      deliverableLink: this.deliverableLink,
      //expectedDueDate: (this.expectedDueDate != undefined && this.expectedDueDate != null && moment(this.expectedDueDate).isValid()) ? this.expectedDueDate : null,
      completionDate: (this.completionDate != undefined && this.completionDate != null && moment(this.completionDate).isValid()) ? this.completionDate : null, 
      //ticketId: Number(this.ticketNumber.substring(2)),
      attachements: this.attachments.filter(a => !a.isRemoved).map(t => t.attachmentId),
      valueMessages : this.valueMessage,
      keyInsights: this.keyInsights,
      product: this.product,
      indication: this.indication,
    });

    this.spinner.show();
    this.sub.sink = this.deliverableClient.add(deliverableDetails).subscribe(
      (data) => {
        this.spinner.hide();
        this.deliverableForm.reset();
        this.notifications.success('Add', 'Deliverable Added Successfully.');
        location.reload();
        this.activeModal.close('ok');
      },
      (error) => {
        this.notifications.error(
          'Error',
          'Something went wrong, please try again later.'
        );
        this.spinner.hide();
      }
    );
  }

  clearFields()
  {
    console.log("clearFields called");
    // Reset the name and allocation form
    this.attachments = [];
    this.deliverableForm.reset();
  }

  // dismissModal() {
  //   this.activeModal.dismiss('canceled');
  // }

  lookforChanges()
  {
    if(this.deliverableForm.touched || this.attachments.length > 0)
    {
      Swal.fire({
        title: 'Are you sure you want to leave?',
        text: 'Changes you made will not be saved.',
        icon: "question",
        showCancelButton: true,
        confirmButtonText: 'Yes, leave',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          this.activeModal.dismiss('canceled');
        }
      });
    }
    else {
      this.activeModal.dismiss('canceled');
    }
  }

}
