import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AuthorizeService, IUser } from 'api-authorization/authorize.service';
import { SubSink } from 'SubSink';
import { UserRoles } from 'src/app/utils/string-constants';
import { HomeClient } from 'src/app/app-services';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss'],
})
export class FullComponent implements OnInit {
  private sub = new SubSink();
  public config: PerfectScrollbarConfigInterface = {};

  constructor(public router: Router, private auth: AuthorizeService, private homeClient: HomeClient) {}

  tabStatus = 'justified';

  public isCollapsed = false;
  ssoRouteUrl:string = "";
  public innerWidth: any;
  public defaultSidebar: any;
  public showSettings = false;
  public showMobileMenu = false;
  public expandLogo = false;
  public showSettingsToggle = false;
  public currentYear = new Date().getFullYear();
  public isToggle = false;
  activenavSelection = 1

  options = {
    theme: 'light', // two possible values: light, dark
    dir: 'ltr', // two possible values: ltr, rtl
    layout: 'vertical', // fixed value. shouldn't be changed.
    sidebartype: 'mini-sidebar', // four possible values: full, iconbar, overlay, mini-sidebar
    sidebarpos: 'fixed', // two possible values: fixed, absolute
    headerpos: 'fixed', // two possible values: fixed, absolute
    boxed: 'full', // two possible values: full, boxed
    navbarbg: 'skin6', // six possible values: skin(1/2/3/4/5/6)
    sidebarbg: 'skin1', // six possible values: skin(1/2/3/4/5/6)
    logobg: 'skin6', // six possible values: skin(1/2/3/4/5/6)
  };
  userData;
  loadingBarColor = '#ef6e6e';

  Logo() {
    this.expandLogo = !this.expandLogo;
  }

  ngOnInit() {
    // if (this.router.url === '/') {
    //   this.router.navigate(['/dashboard/dashboard1']);
    // }
    this.defaultSidebar = this.options.sidebartype;
    this.handleSidebar();
    this.getComponentsRoutingUrlInfo();
    this.sub.sink = this.auth.getUser().subscribe((data) => {
      this.userData = data;
      if (this.userData?.role.includes(UserRoles.Employee)) {
        this.options.layout = 'horizontal';
        this.options.sidebarbg = 'skin4';
        this.options.logobg = 'skin6';
        this.options.navbarbg = 'skin6';
        (this.options.sidebarpos = 'absolute'), // two possible values: fixed, absolute
          (this.options.headerpos = 'absolute'), // two possible values: fixed, absolute
          (this.options.sidebartype = 'full');
        this.defaultSidebar = this.options.sidebartype;
        this.loadingBarColor = '#ff5722';
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.handleSidebar();
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    switch (this.defaultSidebar) {
      case 'full':
      case 'iconbar':
        if (this.innerWidth < 1170) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      case 'overlay':
        if (this.innerWidth < 767) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  toggleSidebarType(isToggle) {
    if(this.options.sidebartype == 'mini-sidebar' && isToggle)
      this.isToggle = true;
    else if(this.options.sidebartype == 'mini-sidebar' && !isToggle)
      this.isToggle = false;
    switch (this.options.sidebartype) {
      case 'full':
      case 'iconbar':
        if(isToggle)
          this.options.sidebartype = 'mini-sidebar';
        break;

      case 'overlay':
        this.showMobileMenu = !this.showMobileMenu;
        break;

      case 'mini-sidebar':
        if (this.defaultSidebar === 'mini-sidebar') {
          this.options.sidebartype = 'full';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }
  getComponentsRoutingUrlInfo()
  {
    this.homeClient.getComponentsRoutingUrlInfo().subscribe(
      (data) => {
      const componentsRoutingUrlInfo = data;
      const jsonData = JSON.parse(componentsRoutingUrlInfo);
      this.ssoRouteUrl = jsonData != null && jsonData != undefined && jsonData.hasOwnProperty('SSORouteUrl') ? jsonData.SSORouteUrl : "";
    });
  }

  toggleSidebarTypeOnMouse() {
    if(!this.isToggle && this.options.sidebartype == 'full') {
      this.options.sidebartype = 'mini-sidebar';
    }
  }
}
