<div class="container-fluid">
    <form [formGroup]="deliverableForm" class="needs-validation">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Deliverable</h4>
            <button type="button" class="close" aria-label="Close" (click)="lookforChanges()">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="">
                <div class="form-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="product">Product<span class="text-danger">*</span></label>
                                        <ng-select class="clearfix" formControlName="product" [searchable]="true" [items]="productList"
                                            [placeholder]="'-- Select --'" [(ngModel)]="product" style="height: 35px"
                                            [ngClass]="{ 'is-invalid': deliverableForm.get('product').invalid && (deliverableForm.get('product').dirty || deliverableForm.get('product').touched) }">
                                            
                                            <!-- <ng-option *ngFor="let opt of productValues" value="opt">{{ opt }}</ng-option> -->
                                        </ng-select>
                                        <div *ngIf="deliverableForm?.get('product')?.errors?.required" class="invalid-feedback">
                                            This Field is Required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="indication">Indication</label>
                                        <ng-select class="clearfix" formControlName="indication" [searchable]="true" [items] ="indicationList"
                                            [placeholder]="'-- Select --'" [(ngModel)]="indication" style="height: 35px"
                                            [ngClass]="{ 'is-invalid': deliverableForm.get('indication').invalid && (deliverableForm.get('indication').dirty || deliverableForm.get('indication').touched) }">
                                            <!-- [items]="indicationValues" -->
                                            <!-- <ng-option *ngFor="let opt of indicationValues" value="opt">{{ opt }}</ng-option> -->
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="valueMessage">Value Message(s)</label>
                                        <ng-select class="clearfix multiselect" formControlName="valueMessage" [searchable]="true" appendTo="body"
                                            [multiple]="true" [items]="valueMessagesList" [(ngModel)]="valueMessage" [placeholder]="'-- Select --'">
                                            <ng-option *ngFor="let opt of valueMessagesList" value="opt">{{ opt }}</ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="keyDeliverables">Deliverable Type<span class="text-danger">*</span></label>
                                        <ng-select class="clearfix" formControlName="keyDeliverables" [searchable]="true" [items]="keyDeliverableValues"
                                            [placeholder]="'-- Select --'" [(ngModel)]="keyDeliverables" style="height: 35px"
                                            [ngClass]="{ 'is-invalid': deliverableForm.get('keyDeliverables').invalid && (deliverableForm.get('keyDeliverables').dirty || deliverableForm.get('keyDeliverables').touched) }">
                                            <ng-option *ngFor="let opt of keyDeliverableValues" value="opt">{{ opt }}</ng-option>
                                        </ng-select>
                                        <div *ngIf="deliverableForm?.get('keyDeliverables')?.errors?.required" class="invalid-feedback">
                                            This Field is Required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="completionDate">Completion Date</label>
                                        <div class="input-group input-sm">
                                            <input formControlName="completionDate" placeholder="MM/DD/YYYY"
                                                maxlength="10" class="form-control" ngbDatepicker #e="ngbDatepicker"
                                                #dateInput (keyup)="onDateInput($event)"
                                                [(ngModel)]="completionDate"
                                                [ngClass]="{ 'is-invalid': deliverableForm.get('completionDate').invalid && (deliverableForm.get('completionDate').dirty || deliverableForm.get('completionDate').touched)}" />
                                            <button
                                                class="btn btn-outline-secondary calendar date-picker-icon-border-radius date-picker-btn"
                                                (click)="e.toggle()" type="button"
                                                [ngClass]="{ 'is-invalid': deliverableForm.get('completionDate').invalid && (deliverableForm.get('completionDate').dirty || deliverableForm.get('completionDate').touched)}"
                                                style="padding: 5px; padding-right: 10px; width: 25px">
                                                <i class="mdi mdi-calendar"></i>
                                            </button>
                                            <div *ngIf="deliverableForm.get('completionDate').errors?.ngbDate"
                                                class="invalid-feedback">
                                                Invalid date.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="keyInsights">Key Insights</label>
                                        <input formControlName="keyInsights" id="keyInsights" type="text" [(ngModel)]="keyInsights"
                                            style="height: 35px" placeholder="Enter Key Insight" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="deliverableLink">
                                            Deliverable Link
                                            <i class="fa fa-info-circle" style="color: #0870B8;" title="Please provide at least one attachment or a deliverable link."></i>
                                        </label>
                                        <input formControlName="deliverableLink" id="deliverableLink" type="text" [(ngModel)]="deliverableLink"
                                            style="height: 35px" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <app-add-deliverable-attachment
                                            [attachements]="attachments" uploadType="new"
                                            (attachmentChange)="handleAttachementChnage($event)"></app-add-deliverable-attachment>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-close" (click)="lookforChanges()">
                Close
            </button>
            <button type="button" class="btn btn-clear" (click)="clearFields()">
                Clear
            </button>
            <button type="button" class="btn btn-primary" [disabled]="deliverableForm.invalid"
                (click)="onSubmitDeliverableForm()">
                Add Deliverable
            </button>
        </div>
    </form>
</div>
