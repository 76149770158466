import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  NgbModule,
  NgbDateAdapter,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { ApiAuthorizationModule } from 'api-authorization/api-authorization.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

// for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';
import {
  CustomAdapter,
  CustomDateParserFormatter,
} from './shared/adapters/datepicker-adapters';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedModule } from './shared.module';
import { IfRolesDirective } from './shared/directives/if-roles.directive';
import { NgApexchartsModule } from 'ng-apexcharts';
import { UiSwitchModule } from 'ngx-ui-switch';
import { CustomUIModule } from './common/custom-ui/custom-ui.module';
import { ResizableColumnDirective } from './shared/directives/resizable-columns/resizable-column.directive';
import { SessionCheckInterceptor } from './services/session-check.interceptor';
import { DndDirective } from './shared/directives/dnd.directive';
import { API_BASE_URL } from './app-services';
import { AuthorizeInterceptor } from '../../api-authorization/authorize.interceptor';
import { identityConstants } from '../environments/environment';
import { NgSelectModule } from '@ng-select/ng-select';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    BlankComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    DndDirective
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    ApiAuthorizationModule,
    NgbModule,
    PerfectScrollbarModule,
    BrowserAnimationsModule,
    // SlimLoadingBarModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    // for Router use:
    LoadingBarRouterModule,
    // for Core use:
    LoadingBarModule,
    NgxSpinnerModule,
    SimpleNotificationsModule.forRoot({
      position: ['bottom', 'right'],
      theClass: 'srs-notification',
      timeOut: 4000,
    }),
    NgApexchartsModule,
    UiSwitchModule,
    CustomUIModule,
    NgSelectModule,
    SweetAlert2Module.forRoot()
  ],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {provide:HTTP_INTERCEPTORS, useClass:SessionCheckInterceptor, multi: true},
    {provide: API_BASE_URL, useValue:identityConstants.srsApiBaseUrl, multi:true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true}
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
