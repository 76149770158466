import {
  Component,
  AfterViewInit,
  EventEmitter,
  Output,
  OnInit,
} from '@angular/core';
import { ApplicationPaths } from 'api-authorization/api-authorization.constants';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig,
  NgbModalOptions,
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AuthorizeService, IUser } from 'api-authorization/authorize.service';
import { BehaviorSubject } from 'rxjs';
import { StringConstants, UserRoles } from 'src/app/utils/string-constants';
import { HomeClient } from 'src/app/app-services';
import { identityConstants } from '../../../environments/environment'
import { AddDeliverableModalComponent } from 'src/app/dashboards/add-deliverable-modal/add-deliverable-modal.component';
declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
})
export class NavigationComponent implements OnInit {
  @Output() toggleSidebar = new EventEmitter<void>();

  public config: PerfectScrollbarConfigInterface = {};

  public showSearch = false;
  public userdata;
  isReadOnly = true;
  isAdmin = false;
  isAgent = false;
  isUser = false;
  identityServerMyProfileUri = '';
  projectTrackerRouteUrl:string = "";
  htaTrackerRouteUrl:string = "";
  evidenceHubRouteUrl:string = "";

  constructor(private modalService: NgbModal, private auth: AuthorizeService, private homeClient: HomeClient) {}

  // This is for Notifications
  notifications: Object[] = [
    {
      btn: 'btn-danger',
      icon: 'ti-link',
      title: 'Luanch Admin',
      subject: 'Just see the my new admin!',
      time: '9:30 AM',
    },
    {
      btn: 'btn-success',
      icon: 'ti-calendar',
      title: 'Event today',
      subject: 'Just a reminder that you have event',
      time: '9:10 AM',
    },
    {
      btn: 'btn-info',
      icon: 'ti-settings',
      title: 'Settings',
      subject: 'You can customize this template as you want',
      time: '9:08 AM',
    },
    {
      btn: 'btn-primary',
      icon: 'ti-user',
      title: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM',
    },
  ];

  // This is for Mymessages
  mymessages: Object[] = [
    {
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM',
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'busy',
      from: 'Sonu Nigam',
      subject: 'I have sung a song! See you at',
      time: '9:10 AM',
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'away',
      from: 'Arijit Sinh',
      subject: 'I am a singer!',
      time: '9:08 AM',
    },
    {
      useravatar: 'assets/images/users/4.jpg',
      status: 'offline',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM',
    },
  ];

  ngOnInit() {
    this.auth.getUser().subscribe((data) => {
      this.userdata = data;
      if (data == null) {
         window.location.href = window.location.href;
      } else {
        this.isReadOnly = (data as any)?.role?.includes(UserRoles.Employee) ?? true;
        this.isAdmin = (data as any)?.role?.includes(UserRoles.Administrator) ?? false;
        this.isAgent = (data as any)?.role?.includes(UserRoles.Agent) ?? false;
        this.isUser = (data as any)?.role?.includes(UserRoles.DeliveryManager) ?? false;
        this.identityServerMyProfileUri = identityConstants.authority + "/Identity/Account/Manage";
      }
    });
    this.getComponentsRoutingUrlInfo();
  }

  handleLogout() {
  }

  getComponentsRoutingUrlInfo()
  {
    this.homeClient.getComponentsRoutingUrlInfo().subscribe(
      (data) => {
      const componentsRoutingUrlInfo = data;
      const jsonData = JSON.parse(componentsRoutingUrlInfo);
      this.projectTrackerRouteUrl = jsonData != null && jsonData != undefined && jsonData.hasOwnProperty('ProjectTrackerRouteUrl') ? jsonData.ProjectTrackerRouteUrl : "";
      this.htaTrackerRouteUrl = jsonData != null && jsonData != undefined && jsonData.hasOwnProperty('HTATrackerRouteUrl') ? jsonData.HTATrackerRouteUrl : "";
      this.evidenceHubRouteUrl = jsonData != null && jsonData != undefined && jsonData.hasOwnProperty('EvidenceHubRouteUrl') ? jsonData.EvidenceHubRouteUrl : "";
    });
  }

  openAddDeliverableModal()
  {
    console.log("openAddDeliverableModal is called");

    let ngbModalOptions: NgbModalOptions = {
      size:'lg',
      backdrop: 'static',
      keyboard: false
    };
    const createModalRef = this.modalService.open(AddDeliverableModalComponent, ngbModalOptions);
    createModalRef.result.then(
      (result) => {
        console.log(result); // Handle success message
      },
      (reason) => {
        console.log(reason); // Handle dismiss or cancel reason
      }
    );
  }
}
