import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import {
  TicketAttachement,
  TicketsClient,
  AttachmentsClient,
  Attachement,
  AddDeliverableAttachmentCommand,
  AddTicketAttachmentCommand,
  RemoveTicketAttachmentCommand,
  DeliverableAttachment,
  DeliverableClient,
  RemoveDeliverableAttachmentCommand,
  EHDeliverableAttachment,
  EHAddDeliverableAttachmentCommand,
  EHRemoveDeliverableAttachmentCommand,
} from 'src/app/app-services';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { SubSink } from 'SubSink';
import { NotificationsService } from 'angular2-notifications';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ErrorMessages, StringConstants } from 'src/app/utils/string-constants';


@Component({
  selector: 'app-add-deliverable-attachment',
  templateUrl: './add-deliverable-attachment.component.html',
  styleUrls: ['./add-deliverable-attachment.component.scss']
})
export class AddDeliverableAttachmentComponent implements OnInit, OnDestroy {
  private sub = new SubSink();
  @Input()
  ticketId: number;
  @Input()
  deliverableId: number;
  @Input()
  attachements: Array<EHDeliverableAttachment>;
  @Input()
  uploadType: string;
  @Output()
  attachmentChange:EventEmitter<Array<EHDeliverableAttachment>> = new EventEmitter<Array<EHDeliverableAttachment>> ();

  fileToupload: File;
  uploadedFiles: Array<Attachement> = [];
  @ViewChild('fileInput')
  fileInputElement: ElementRef;

  constructor(
    private deliverableClient: DeliverableClient,
    private attchmentClinet: AttachmentsClient,
    private notifications: NotificationsService,
    private loadingBar: LoadingBarService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {}
  ngOnDestroy(): void {this.sub.unsubscribe();}

  handleFileInput(files: FileList) {
    this.fileToupload = files[0];
    if (this.fileToupload) {
      const allowedFileTypes = [
        'image/png',
        'image/svg+xml',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.ms-powerpoint',
        'application/pdf',
        'text/xml',
        'text/plain',
        'application/x-zip-compressed',
        'image/jpeg',
        'image/gif',
        'image/tiff',
      ];
      if (allowedFileTypes.findIndex((a) => a === this.fileToupload.type) < 0) {
        this.notifications.error(
          'Invalid file',
          'Allowed file types: Image, XML, Word, Excel, CSV, Powerpoint, PDF, Text, Zip'
        );
        this.fileInputElement.nativeElement.value = null;
        this.fileToupload = null;
        this.uploadedFiles = [];
      } else if(this.fileToupload.size > 10000000) {
        this.notifications.error(
          'Invalid File Size',
          "File Size Can't be Greater than 10MB"
        );
        this.fileInputElement.nativeElement.value = null;
        this.fileToupload = null;
      } else {
        this.loadingBar.start();
        this.spinner.show();
        this.sub.sink = this.attchmentClinet
          .uploadAttachement({
            data: this.fileToupload,
            fileName: this.fileToupload.name,
          })
          .subscribe(
            (res) => {
              this.uploadedFiles = [...this.uploadedFiles, res];
            },
            (err) => {
              this.loadingBar.complete();
              this.spinner.hide();
              this.notifications.error(ErrorMessages.genericError);
              this.fileInputElement.nativeElement.value = null;
              this.fileToupload = null;
              this.uploadedFiles = [];
            },
            () => {
              this.loadingBar.complete();
              this.spinner.hide();
            }
          );
      }
    }
  }

  get activeAttachements() {
    return this.attachements.filter((a) => a.isRemoved === false);
  }

  handleUploadCancel() {
    this.fileInputElement.nativeElement.value = null;
    this.fileToupload = null;
    this.uploadedFiles = [];
  }
  handleAttach() {
    if(this.uploadType == 'new'){
      if (this.uploadedFiles.length > 0) {
        this.attachements = [
          ...this.attachements,
          new EHDeliverableAttachment({
            id: 0,
            attachmentId: this.uploadedFiles[0].id as number,
            attachment: this.uploadedFiles[0],
            isRemoved: false,
            deliverableId:0
          }),
        ];
        this.attachmentChange.emit(this.attachements);
        this.fileInputElement.nativeElement.value = null;
        this.fileToupload = null;
        this.uploadedFiles = [];
      }
    } else if (this.uploadedFiles.length > 0) {
      this.sub.sink = this.deliverableClient
        .attachFile(
          this.ticketId,
          new EHAddDeliverableAttachmentCommand({
            deliverableId: this.deliverableId as number,
            attachmentId: this.uploadedFiles[0].id as number,
          })
        )
        .subscribe((res) => {
          this.attachements = [
            ...this.attachements,
            new EHDeliverableAttachment({
              id: res,
              attachmentId: this.uploadedFiles[0].id as number,
              attachment: this.uploadedFiles[0],
              isRemoved: false,
              deliverableId: this.deliverableId,
            }),
          ];
          this.attachmentChange.emit(this.attachements);
          this.notifications.success(
            'Success',
            'Attachment Added Successfully'
          );
          this.fileInputElement.nativeElement.value = null;
          this.fileToupload = null;
          this.uploadedFiles = [];
        });
    }
  }
  handleRemoveAttachement(att: EHDeliverableAttachment) {
    //console.log("TicketId: ",this.ticketId, "DeliverableId: ", this.deliverableId);
    if(this.ticketId != 0 && att.deliverableId != 0)
    this.sub.sink = this.deliverableClient
      .deleteAttachment(
        new EHRemoveDeliverableAttachmentCommand({ id: att.id })
      )
      .subscribe((res) => {
        att.isRemoved = true;
        this.attachmentChange.emit(this.attachements);
      });
      else {
        att.isRemoved = true;
        this.attachmentChange.emit(this.attachements);
      }
  }
  handleDownloadFile(att:EHDeliverableAttachment){
    this.attchmentClinet.getFile(att.attachment.name).subscribe(res => {
      const a = document.createElement('a')
      const objectUrl = URL.createObjectURL(res.data);
      a.href = objectUrl
        a.download = att.attachment.userFileName;
        a.click();
        URL.revokeObjectURL(objectUrl);
    });
  }

  getFileTypeIcon(fileType: string) {
    let icon = 'fas fa-file';
    switch (fileType) {
      case 'image/png':
        icon = 'far fa-file-image';
        break;
      case 'image/jpeg':
        icon = 'far fa-file-image';
        break;
      case 'image/gif':
        icon = 'far fa-file-image';
        break;
      case 'image/tiff':
        icon = 'far fa-file-image';
        break;
      case 'image/svg+xml':
        icon = 'far fa-file-image';
        break;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        icon = 'far fa-file-excel';
        break;
      case 'application/vnd.ms-excel':
        icon = 'far fa-file-excel';
        break;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        icon = 'far fa-file-word';
        break;
      case 'application/msword':
        icon = 'far fa-file-word';
        break;
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        icon = 'far fa-file-powerpoint';
        break;
      case 'application/vnd.ms-powerpoint':
        icon = 'far fa-file-powerpoint';
        break;
      case 'application/pdf':
        icon = 'far fa-file-pdf';
        break;
      case 'text/xml':
        icon = 'far fa-file-code';
        break;
      case 'text/plain':
        icon = 'far fa-file-alt';
        break;
      case 'application/x-zip-compressed':
        icon = 'far fa-file-archive';
        break;
      default:
        break;
    }
    return icon;
  }
}
